import React            from 'react';
import './style.scss'
import Carousel         from "react-multi-carousel";
import LogoCustomer from "../../../assets/images/logo-customer.png";


const CustomerFeature = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items     : 5
    },
    desktop          : {
      breakpoint: {max: 3000, min: 1024},
      items     : 4
    },
    tablet           : {
      breakpoint: {max: 1024, min: 464},
      items     : 3
    },
    mobile           : {
      breakpoint: {max: 464, min: 0},
      items     : 2
    }
  };

  return <div className="customer-wrap">
    <h2 className="customer__title">KHÁCH HÀNG TIÊU BIỂU</h2>
    <div className="container-1">
      <Carousel
        responsive={responsive}
        itemClass="customer__container"
        additionalTransfrom={0}
        customLeftArrow={<i className='customer__left-arrow'/>}
        customRightArrow={<i className='customer__right-arrow'/>}
        arrows
        draggable={false}
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
      >
        {
          props.customers ? props.customers.map((customer) => {
            return <Customer customer={customer}/>
          }) : ''
        }
      </Carousel>
    </div>
  </div>;
}

const Customer = (props) => {
  return <div className="customer-box noselect">
    <div className="customer__logo">
      <img src={props.customer} alt="khách hàng jac"/>
    </div>
  </div>
}

export default CustomerFeature