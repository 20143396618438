import axios from 'axios'
import {API_URL} from '../config'

const API     = {
  POST_PROMOTION_EMAIL: API_URL + 'promotion/create'
}

var createPromotionEmail = async (email) => {
  try {
    let response = await axios.post(API.POST_PROMOTION_EMAIL, {
    	'email': email
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export {createPromotionEmail}