import React, {useEffect, useState}                                           from 'react';
import {DatePicker, Input, Checkbox, Cascader, Modal, Row, Col, Select}       from 'antd';
import {CollapOrderIcon}                                                      from '../../component/Icon';
import './style.scss'
import Maps                                                                   from '../DirectionMaps'
import {AddressFromIcon, AddressToIcon, OrderCarIcon, OrderCarIconBackground} from '../Icon'
import {createOrder}                                                          from "../../../service/OrderService";
import {
  LoadingOutlined
}                                                                             from '@ant-design/icons';
import {getProductCategory}                                                   from "../../../service/CategoryService";
import moment                                                                 from 'moment';

const {Option} = Select

const OrderFormModal = (props) => {
  const [oneWay, setOneWay]                       = useState(true)
  const [twoWay, setTwoWay]                       = useState(false)
  const [enableVAT, setEnableVAT]                 = useState()
  const [fromInput, setFromInput]                 = useState()
  const [toInput, setToInput]                     = useState()
  const [activeForm, setActiveForm]               = useState()
  const [distance, setDistance]                   = useState('')
  const [duration, setDuration]                   = useState('')
  const [fromAddress, setFromAddress]             = useState('')
  const [toAddress, setToAddress]                 = useState('')
  const [fullname, setFullName]                   = useState('')
  const [phone, setPhone]                         = useState('')
  const [timeStart, setTimeStart]                 = useState('')
  const [timeEnd, setTimeEnd]                     = useState('')
  const [categoryId, setCategoryId]               = useState('')
  const [visible, setVisible]                     = useState(false)
  const [modalErrorVisible, setModalErrorVisible] = useState(false)
  const [orderResponse, setOrderResponse]         = useState([])
  const [errorResponse, setErrorResponse]         = useState("")
  const [loading, setLoading]                     = useState(false)
  const [carTypeOptions, setCarTypeOptions]       = useState(false)
  const [disabledTime, setDisabledTime]           = useState('')


  useEffect(() => {
    getTypeCarCategory()
  }, [])

  const getTypeCarCategory = async () => {
    let response = await getProductCategory()
    if (response.code === 0) {
      let categoriesData = response.data;
      for (let i in categoriesData) {
        if (categoriesData[i].id === 1) {
          var result = []
          for (let j in categoriesData[i].sub_category) {
            result.push({
              "value": categoriesData[i].sub_category[j].id,
              "label": categoriesData[i].sub_category[j].name
            })
          }
          setCarTypeOptions(result)
        }
      }
    }
  }

  const setInputForm = (inputElement) => {
    setFromInput(inputElement)
  }

  const setInputTo = (inputElement) => {
    setToInput(inputElement)
  }

  const changeWayType = (e) => {
    if (e.target.value === '1') {
      setOneWay(true)
      setTwoWay(false)
      setTimeEnd('')
    } else {
      setOneWay(false)
      setTwoWay(true)
    }
  }

  const changeTypeCar = (types) => {
    setCategoryId(types[0])
  }

  const bookNow = async () => {
    var dataRequest = {
      fullname    : fullname,
      phone       : phone,
      time_end    : timeEnd,
      time_start  : timeStart,
      type        : twoWay ? 2 : 1,
      has_vat         : enableVAT ? 1 : 0,
      km          : distance,
      from_address: fromAddress,
      to_address  : toAddress,
      category_id : categoryId,
    }
    setLoading(true)
    var response = await createOrder(dataRequest)
    setLoading(false)
    if (response.code === 0) {
      setOrderResponse(response.data)
      setVisible(true)
    } else {
      var errorElement = []
      Object.values(response.message).map((e, key) => {
        errorElement.push(<p key={key} className="reset-margin text-red">{e[0]}</p>)
      })
      setErrorResponse(errorElement)
      setModalErrorVisible(true)
    }
  }

  const formatMoney = (amount) => {
    return parseInt(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').slice(0, -3);
  }

  function disabledDateStart(current) {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  function disabledDateEnd(current) {
    return current && current._d.getTime() < new Date(timeStart).getTime();
  }

  return <div className={"order-form-modal order-form-modal_" + (activeForm ? 'open' : 'close')}>
    <div className={"button-form-order " + (activeForm ? 'close' : 'open')} onClick={() => {
      setActiveForm(!activeForm)
    }}>
      <OrderCarIconBackground/>
    </div>
    <div className={"order-form-modal__form " + (activeForm ? 'open' : 'close')}>
      <div className="order-form__header">
        <div className="wrap-icon-order" onClick={() => {
          setActiveForm(!activeForm)
        }}>
          <OrderCarIcon className="image-order-car"/>
        </div>
        <span className="order-form__header--text">Đặt Xe giá rẻ tại Hà Nội</span>
        <span
          className={"order-form__header--icon " + (activeForm ? 'order-form__header--icon-active' : '')}
          onClick={() => {
            setActiveForm(!activeForm)
          }}
        >
          <CollapOrderIcon/>
          <div className="clearfix"></div>
        </span>
      </div>
      <div className="order-form__content">
        <div className="order-form__name">
          <Input name="fullname" value={fullname} className="order-form__input" placeholder="Họ và tên"
                 onChange={(e) => setFullName(e.target.value)}/>
        </div>
        <div className="order-form__phone">
          <Input name="phone" value={phone} className="order-form__input" placeholder="Số điện thoại"
                 onChange={(e) => setPhone(e.target.value)}/>
        </div>
        <div className="order-form__start-place">
          {/*<Input className="order-form__input order-form__input--place"*/}
          {/*       addonBefore={<span><AddressFromIcon/> Điểm đi</span>}/>*/}
          {fromInput}
        </div>
        <div className="order-form__end-place">
          {/*<Input className="order-form__input order-form__input--place"*/}
          {/*       addonBefore={<span><AddressToIcon/> Điểm đên</span>}/>*/}
          {toInput}
        </div>
        <div className="order-form__distance-notify">
          {
            distance && duration &&
            <span>Dự kiến đi {distance} km trong {duration}</span>
          }
        </div>
        <div className="order-form__option">
          <Checkbox
            value='1'
            checked={oneWay}
            onChange={changeWayType}
          >
            Một chiều
          </Checkbox>
          <Checkbox
            value='2'
            checked={twoWay}
            onChange={changeWayType}
          >
            Hai chiều
          </Checkbox>
          <Checkbox
            checked={enableVAT}
            onChange={(e) => {
              // data.vat = e.target.checked
              setEnableVAT(e.target.checked)
            }}
          >
            VAT
          </Checkbox>
        </div>
        <div className="order-form__car-type">
          <Input.Group compact addonBefore="Điểm đên" className="order-form__car-type-group">
            <Select style={{width: '10rem'}} defaultValue="Loại Xe" addonBefore="Điểm đên"
                    disabled></Select>
            <Cascader name="product_id" className="order-form__car-type--input order-form__input--place"
                      options={carTypeOptions} placeholder="Chọn loại xe" onChange={changeTypeCar}/>
          </Input.Group>
        </div>
        <div className="order-form__time">
          <DatePicker
            disabledDate={disabledDateStart}
            placeholder="Thời gian đón"
            onChange={(date, dateString) => {
              setTimeStart(dateString)
            }}/>
          <DatePicker
            disabledDate={disabledDateEnd}
            disabled={!twoWay}
            placeholder="Thời gian về"
            onChange={(date, dateString) => {
              setTimeEnd(dateString)
            }}/>
        </div>
        <div className="order-form__btn-book-box">
          <button className="order-form__btn-book" id="book-now" onClick={bookNow}>
            <span style={{marginRight: '5px'}}>Đặt Lịch Ngay</span>
            {
              loading && <LoadingOutlined/>
            }

          </button>
        </div>
      </div>
    </div>
    <div className="order-form-modal__maps">
      <Maps
        setDistance={setDistance}
        setDuration={setDuration}
        setActiveForm={setActiveForm}
        setFromAddress={setFromAddress}
        setToAddress={setToAddress}
        setInputForm={setInputForm}
        setInputTo={setInputTo}/>
    </div>
    <div>
      <Modal
        title="Đặt xe thành công"
        visible={visible}
        width="700"
        cancelText="Ok, Tôi đã hiểu"
        centered="true"
        okButtonProps={{style: {display: 'none'}}}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <Row className="margin-bottom-sm">
          <Col span={24} className="text-center order-response__header order-response-border">
            <h2 className="order-response__title">GIÁ THAM KHẢO</h2>
            <h1 className="order-response__price">
              {formatMoney(orderResponse.price) + " đ"}
            </h1>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11} className="order-response-border padding-sm">
            <h3 className="text-center">
              CHI TIẾT ĐẶT XE
            </h3>
            <p><span>Khách hàng: </span><span>{orderResponse.fullname}</span></p>
            <p><span>SDT: </span><span>{orderResponse.phone}</span></p>
            <p>
              <span>Lịch trình: </span><span>{orderResponse.from_address + ' - ' + orderResponse.to_address}</span>
            </p>
            <p><span>Ngày đi: </span><span>{orderResponse.time_start}</span></p>
            <p><span>Ngày về:</span><span>{orderResponse.time_end}</span></p>
            <p><span>Số Km: </span><span>{orderResponse.km + ' km'}</span></p>
            <p><span>Loại xe: </span><span>{orderResponse.category_name}</span></p>
            <p><span>VAT: </span><span>{orderResponse.has_vat ? 'Đã có' : 'Chưa có'}</span></p>
          </Col>
          <Col span={12} className="order-response-border padding-sm">
            <h3 className="text-center">
              LƯU Ý
            </h3>
            <ul className="order-response__note-list">
              <li>Nhân viên kinh doanh của chúng tôi sẽ gọi lại cho Quý khách để xác nhận lịch trình và báo giá thực tế.</li>
              <li>Giá trên là giá tham khảo. Giá thực tế sẽ không chênh lệch quá 10% so với giá tham khảo.</li>
              <li>Độ chính xác của báo giá sẽ phụ thuộc vào chi tiết quãng đường mà Quý khách chọn</li>
              <li>Giá trên đã bao gồm phí lưu đêm theo ngày (nếu có), chưa bao gồm phí cầu đường, phí ăn ngủ qua đêm cho lái xe (Nếu có)</li>
              <li>Báo giá này chỉ có giá trị khi Quý khách chọn điểm đi tại Hà Nội</li>
            </ul>

          </Col>
        </Row>
      </Modal>
      <Modal
        visible={modalErrorVisible}
        title="Lỗi đặt xe"
        footer={false}
        onCancel={() => {
          setModalErrorVisible(false)
        }}
      >
        {errorResponse}
      </Modal>
    </div>
  </div>;

}

export default OrderFormModal