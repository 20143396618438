import React, {useState, useEffect}                                             from 'react';
import CategoryMenu                                                             from "../../component/CategoryMenu";
import {Col, Row}                                                               from "antd";
import ProductGrid                                                              from "../../component/ProductGrid";
import SectionBackground
                                                                                from '../../../assets/images/section-category-header.png';
import JacSection, {SectionContent, SectionTitlePrimary, SectionTitleSecondary} from '../../component/Section'
import './style.scss'
import {getListProduct}                                                         from "../../../service/ProductService";
import {useParams}                                                              from "react-router";
import {LIMIT_PRODUCT}                                                              from "../../../config/index";
import OrderForm from "../../component/OrderFormModal";
import {CarIcon} from "../../component/Icon";
import MetaTags from 'react-meta-tags';

const Category = () => {
  const [categories, setCategories]       = useState([]);
  const [products, setProduct]       = useState([]);
  const [categoryTitle, setCategoryTitle]       = useState('');
  const [banner, setBanner]       = useState('');
  const [parentCategoryTitle, setParentCategoryTitle]       = useState('');
  const [categoryDescription, setCategoryDescription]       = useState('');
  const [metaTitle, setMetaTitle]       = useState('');
  const [metaKeywords, setMetaKeywords]       = useState('');
  const [metaDescription, setMetaDescription]       = useState('');
  var {childCategoryId, parentCategoryId} = useParams()

  useEffect(() => {
    getCategoryData();
  }, [childCategoryId, parentCategoryId])

  async function getCategoryData() {
    var categoryId = childCategoryId
    var limit;
    if (!childCategoryId && parentCategoryId) {
      categoryId = parentCategoryId
      limit = LIMIT_PRODUCT
    }
    if (!childCategoryId && !parentCategoryId) {
      categoryId = '1'
      limit = false
    }

    let response   = await getListProduct(categoryId, limit)
    if(response.code === 0) {
      setProduct(response.data.products)
      setCategoryTitle(response.data.name)
      setParentCategoryTitle(response.data.parent_name)
      setCategoryDescription(response.data.description)
      setMetaTitle(response.data.meta_title)
      setMetaKeywords(response.data.meta_keyword)
      setMetaDescription(response.data.meta_description)
      setCategories(response.data.sub_category);
      if (response.data.banner) {
        setBanner(response.data.banner);
      } else {
        setBanner(SectionBackground);
      }
    } else {
      // show error message
    }
  }

  let categoryElements = [];
  if(categories.length > 0) {
    for (let i in categories) {
      if (categories[i].products.length > 0) {
        categoryElements.push(<ProductGrid
          products={categories[i].products}
          linkDetail={"/danh-muc/" + parentCategoryId + '/' + categories[i].slug}
          categoryId={categories[i].id}
          titleCategorySub={categories[i].name}
        />)
      }
    }
  } else if(products){
    categoryElements.push(<ProductGrid
      products={products}
      titleCategorySub={categoryTitle}
    />)
  }


  return (<div className="category-page">
    <MetaTags>
      <title>{metaTitle}</title>
      <meta id="meta-description" name="description" content={metaDescription} />
      <meta id="meta-keywords" name="keywords" content={metaKeywords} />
    </MetaTags>
    <section className="category__banner">
      <div className="category__banner--order-form">
        <OrderForm/>
      </div>

      <JacSection background={banner} align="center" className="category-header">
      </JacSection>
    </section>

    <div className="container">
      <Row className="category-main">
        <Col lg={5} sm={24} className="category__menu">
          <CategoryMenu titleCategory="Dịch vụ"/>
        </Col>
        <Col lg={19} sm={24} className="category__products">
          <div className="title-header title-category">
            {parentCategoryTitle !== "" &&
              parentCategoryTitle + "/ "
            }
            {categoryTitle}
          </div>
          {categoryElements}
        </Col>
      </Row>
    </div>
  </div>)
}

export default Category