import React      from 'react';
import {Col, Row} from "antd";
import FiveStar   from "../../../assets/images/5-star.png";
import {Link}     from "react-router-dom";
import './style.scss'
import {format_product_link} from "../../../utils/helper"

const ProductItem = (props) => {
  let product = props.product
  return <div className="product-box noselect">
    <div className="product__image-box">
      <Link to={format_product_link(product)}><img src={product.thumbnail} alt={product.name} draggable={false}/></Link>
    </div>
    <Row className="product__info-box">
      <Col span={12} className="product__info-left-box">
        <div><Link to={format_product_link(product)} className="product__info--name">{product.name}</Link></div>
        <div className="product__info--rate"><img src={FiveStar} alt="đánh giá xe"/></div>
        <div className="product__info--model">Đời xe: {product.model}</div>
      </Col>
      <Col span={12} className="product__info-right-box">
        <div className="product__info--price">Giá: Liên hệ</div>
        <div className="product__info--contact">
          <Link to={format_product_link(product)}>Liên Hệ</Link>
        </div>
      </Col>
    </Row>
  </div>
}

export default ProductItem