import React, {useState} from 'react';
import './style.scss';
import {Row, Col, Button, Modal} from 'antd';
import FooterMaps                                      from '../../component/FooterGoogleMaps'
import {
  FacebookIcon,
  InstaIcon,
  MesIcon,
  YoutubeIcon,
  AddressFooterIcon,
  PhoneFooterIcon,
  EmailFooterIcon,
  FlyIcon
} from '../../component/Icon'
import BoCongThuong from "../../../assets/images/icon-bo-cong-thuong.png";
import IconPhone from "../../../assets/images/icon-phone-big.png";
import IconMessenger from "../../../assets/images/icon-messenger.png";
import {createPromotionEmail} from "../../../service/PromotionService";

const Footer = (props) => {
  const [emailPromotion, setEmailPromotion] = useState([]);
  const [visible, setVisible]                     = useState(false)
  const [message, setMessage]                     = useState(false)
  const [modalErrorVisible, setModalErrorVisible] = useState(false)

  async function clickPromotionEmail() {
    let response   = await createPromotionEmail(emailPromotion)
    var message = ''

    if(response.code === 0) {
      message = 'Thành công rồi, chúng tôi sẽ gửi nội dung khuyến mại vào email: ' + emailPromotion + ' khi có thông báo mới nhất'
    } else {
      message = 'Nhập sai trường email đăng ký, vui lòng nhập lại'
    }
    setMessage(message)
    setVisible(true)
  }

  const handleChangeEmail = (e) => {
    setEmailPromotion(e.target.value)
  }

  return (
    <footer>
      <div className="footer">

        <div className="button-contact">
          <div className="call-now">
            <a href={"tel:" + props.info.phone}><img className="img-call-now" src={IconPhone} alt="liên hệ điện thoại JAC"/></a>
          </div>
        </div>
        <Row className="container">
          <Col span={8} className="footer-col info-contact">
            <h4 className="footer-col__title">THÔNG TIN LIÊN LẠC</h4>
            <Row className="footer-item">
              <Col span={2} className="footer-item__icon"><AddressFooterIcon /></Col>
              <Col span={22}>
                <p className="footer-item__content">{props.info.address}</p>
              </Col>
            </Row>
            <Row className="footer-item">
              <Col span={2} className="footer-item__icon"><PhoneFooterIcon /></Col>
              <Col span={22}>
                <p className="footer-item__content">{props.info.time_open}</p>
              </Col>
            </Row>
            <Row className="footer-item">
              <Col span={2} className="footer-item__icon"><EmailFooterIcon /></Col>
              <Col span={22} className="footer-item__icon">
                <p className="footer-item__content">{props.info.email}</p>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="footer-col">
            <div className="form-email">
              <input name="email_promotion" type="text" placeholder="Nhập email để nhận khuyến mại" className="email-input" onChange={handleChangeEmail} />
              <Button size="large" className="button-send-email" onClick={clickPromotionEmail} >
                Gửi đi <FlyIcon className="icon-fly" />
              </Button>
            </div>
            <div className="logo-footer"><img src={BoCongThuong} alt="jac - bộ công thương"/></div>
          </Col>
          <Col span={8} className="footer-col text-right">
            <div className="footer__maps">
              <FooterMaps
                isMarkerShown={true}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="copyright">
        <Row className="container">
          <Col span={12} className="copyright__left"><span>© 2020 JAC. All Rights Reserved</span></Col>
          <Col span={12} className="copyright__right">
            <span className="social">
              <span> <a href={props.info.facebook} target="_blank"><FacebookIcon/></a> </span>
              <span> <a href={props.info.youtube} target="_blank"><YoutubeIcon/></a> </span>
              <span> <a href={props.info.instagram} target="_blank"><InstaIcon/></a> </span>
              <span> <a href={props.info.whatsapp} target="_blank"><MesIcon/></a> </span>
            </span>
          </Col>
        </Row>
      </div>
      <Modal
          visible={visible}
          title="Thông báo"
          footer={false}
          onCancel={() => {
            setVisible(false)
          }}
      >
        {message}
      </Modal>

    </footer>)
}

export default Footer