import React, {useState} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
}                        from 'react-places-autocomplete';
import {Input}           from "antd";

/*global google*/
const LocationSearchInput = (props) => {
  const [address, setAddress] = useState()

  const handleChange = addressData => {
    setAddress(addressData);
    props.setMyAddress(addressData);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0].formatted_address)
        props.setMyAddress(results[0].formatted_address)
        getLatLng(results[0]).then(latLng => {
          props.setLocation(latLng)
        })
      })
      .catch(error => console.error('Error', error));
  };


  const searchOption = props.inHanoi ? {
    location: new google.maps.LatLng(21.003369, 105.820175),
    radius: 20000,
    strictBounds: true,
    componentRestrictions: {country: ['vn']}
  } : {
    componentRestrictions: {country: ['vn']}
  }


  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOption}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div>
          <Input
            {...getInputProps({
              placeholder: props.placeholder,
              className  : 'order-form__input order-form__input--place',
            })}
            value={address}
            addonBefore={props.addonBefore}
          />
          <div className="autocomplete-dropdown">
            <div className="autocomplete-dropdown-container">
              {loading && <div className="autocomplete-dropdown-loadding">Đang tải...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style     = suggestion.active
                  ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                  : {backgroundColor: '#ffffff', cursor: 'pointer'};
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default LocationSearchInput