import React, {useState, useEffect}                                             from 'react';
import JacSection, {SectionTitleSecondary, SectionTitlePrimary, SectionContent} from '../../component/Section'
import {Col, Row}                                                               from "antd";
import SectionBackground                                                        from "../../../assets/images/section-blog-header.png";
import AboutUsImg                                                               from '../../../assets/images/about-us-image.png'
import SectionBackground1                                                       from '../../../assets/images/about-us-section-bg.png'
import {ServiceIconsBox}                                                        from '../../component/ServiceIntroduce'
import FeedBack                                                                 from '../../component/FeedBack'
import {getCustomerFeedBacks} from '../../../service/BaseInfoService'
import './style.scss'
import CustomerFeature from "../../component/CustomerFeature";
import {getAboutUsContent, getLogoCustomer} from "../../../service/InfoService";
import CustomerAvatar from '../../../assets/images/product-1.png';
import ProductCarousel from "../../component/ProductCarousel";
import MetaTags from "react-meta-tags";



const AboutUs = () => {
  const [feedBacks, setFeedBacks] = useState([])
  const [contentAboutUs, setContentAbountUs] = useState([])
  const [logoCustomers, setLogoCustomers] = useState([])
  useEffect(() => {
      getDataAboutUsContent();
      getDataLogoCustomer();
  }, []);

  const getDataAboutUsContent = async () => {
      let response   = await getAboutUsContent()
      if(response.code === 0) {
          let dataInfo = response.data
          setContentAbountUs(dataInfo)

          setFeedBacks([
            {
              avatar: CustomerAvatar,
              address: dataInfo['feedback_author_1'],
              content: dataInfo['feedback_text_1']
            },
            {
              avatar: CustomerAvatar,
              address: dataInfo['feedback_author_2'],
              content: dataInfo['feedback_text_2']
            }
          ]);

      }
  }

  const getDataLogoCustomer = async () => {
      let response   = await getLogoCustomer()
      if(response.code === 0) {
          let dataInfo = response.data
          console.log(dataInfo)
          setLogoCustomers(dataInfo)
      }
  }


  return (<div className="about-us-page">
      <MetaTags>
          <title>JAC - Công ty vận tải du lịch - Về chúng tôi</title>
          <meta id="meta-keywords" name="keywords" content="về chúng tôi, JAC, thuê xe, thuê xe du lịch, thuê xe cưới hỏi, dịch vụ cứu hộ" />
          <meta id="meta-description" name="description" content="Về chúng tôi, JAC - kinh doanh vận tải hành khách, kinh doanh dịch vụ du lịch, bảo dưỡng sửa chữa xe ô tô, kinh doanh phụ tùng ô tô, cứu hộ giao thông" />
      </MetaTags>
    <JacSection background={SectionBackground} align="right" className="blog-header">
      <SectionTitlePrimary className="text-right about-us__section--title-primary">VỀ CHÚNG TÔI</SectionTitlePrimary>
      <SectionTitleSecondary className="text-right about-us__section--title-secondary">
        Công ty TNHH MTV Vận tải & Du Lịch JAC
      </SectionTitleSecondary>
    </JacSection>
    <div className="container">
      <Row className="about-us-description-box">
        <Col lg={12} sm={24} className="about-us-description">
          <h3 className="about-us-description__title-primary">
            VỀ CHÚNG TÔI
          </h3>
          <div className="about-us-description__title-secondary-box">
            <div className="about-us-description__title-secondary--line">

            </div>
          </div>
          <p className="about-us-description__content">
              <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_1}}></div>
          </p>
        </Col>
        <Col lg={12} sm={24} className="about-us-description__image-wrap">
          <div className="about-us-description__image-box">
            <img className="about-us-description__image" src={AboutUsImg} alt="jac về chúng tôi"/>
          </div>
        </Col>
      </Row>
        <div className="about-us-content">
            <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_add_1}}></div>
        </div>
    </div>
    <JacSection background={SectionBackground1} align="center" className="about-us-section">
      <SectionContent className="about-us__section-2--content">
          <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_2}}></div>
      </SectionContent>
    </JacSection>
      {
          contentAboutUs.block_text_add_2 !== 'none' &&
          <div className="container">
              <div className="about-us-content">
                  <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_add_2}}></div>
              </div>
          </div>
      }
    <section className="about-us__service-box">
      <ServiceIconsBox greyBg={true}/>
    </section>
      {
          contentAboutUs.block_text_add_3 !== 'none' &&
          <div className="container">
              <div className="about-us-content">
                  <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_add_3}}></div>
              </div>
          </div>
      }
    <FeedBack feedBacks={feedBacks}/>
      {
          contentAboutUs.block_text_add_4 !== 'none' &&
          <div className="container">
              <div className="about-us-content">
                  <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_add_4}}></div>
              </div>
          </div>
      }
    <CustomerFeature customers={logoCustomers} />
      {
          contentAboutUs.block_text_add_5 !== 'none' &&
          <div className="container">
              <div className="about-us-content">
                  <div dangerouslySetInnerHTML={{__html: contentAboutUs.block_text_add_5}}></div>
              </div>
          </div>
      }
  </div>)
}

export default AboutUs