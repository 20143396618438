import React, {useState, useEffect}    from 'react';
import './style.scss'
import {MenuIcon}           from '../../component/Icon'
import {Menu}               from 'antd';
import {
  Link,
  useParams
}                           from "react-router-dom";
import {getProductCategory} from '../../../service/CategoryService'



const {SubMenu} = Menu;

const CategoryMenu = (props) => {
  var {childCategoryId, parentCategoryId} = useParams()
  const [categories, setCategories] = useState({})

  var detectSelectKey = () => {
    if (childCategoryId) {
      return childCategoryId
    } else if (parentCategoryId) {
      return parentCategoryId
    } else {
      return '1'
    }
  }

  var detectOpenKey = () => {
    if (parentCategoryId) {
      return parentCategoryId + '_parent'
    } else {
      return '1_parent'
    }
  }

  useEffect(() => {
    getCategory()
  }, [])

  const getCategory = async () => {
    let response   = await getProductCategory()
    if(response.code === 0) {
      let categoriesData = response.data
      setCategories(categoriesData)
    }
  }


  const renderCategory = () => {
    var result = []
    for(let i in categories) {
      let category = categories[i]
      if(category.sub_category) {
        let subCategories = category.sub_category
        let subCategoryElement = []
        for(let j in subCategories) {
          subCategoryElement.push(<Menu.Item key={subCategories[j].slug}><Link to={"/danh-muc/"+category.slug+"/" + subCategories[j].slug}>{subCategories[j].name}</Link></Menu.Item>)
        }
        result.push(<SubMenu key={category.slug + '_parent'} title={category.name}>
          <Menu.Item key={category.slug}><Link to={"/danh-muc/" + category.slug}>Tất cả</Link></Menu.Item>
          {subCategoryElement}
        </SubMenu>)
      } else {
        result.push(<Menu.Item key={categories[i].slug}><Link to="/danh-muc/100">{categories[i].name}</Link></Menu.Item>)
      }
    }
    return result;
  }


  return <div className="category-menu">
    <div className="title-header title-category">

      <div className="icon-menu">
        <MenuIcon className="icon-menu__img"/>
      </div>
      {props.titleCategory}
    </div>
    <div>
      <Menu
        defaultSelectedKeys={[detectSelectKey()]}
        defaultOpenKeys={[detectOpenKey()]}
        selectedKeys={[detectSelectKey()]}
        mode="inline"
      >
        {renderCategory()}
      </Menu>
    </div>
  </div>;
}

export default CategoryMenu