import React, {useState, useEffect}                                             from 'react'
import BannerSlider                                                             from "../../component/BannerSlider"
import ServiceIntroduce                                                         from "../../component/ServiceIntroduce"
import TitleLeftRight                                                           from '../../component/TitileLeftRight'
import ProductCarousel                                                          from '../../component/ProductCarousel'
import {ButtonTransparent}                                                      from '../../component/Button'
import JacSection, {SectionContent, SectionTitlePrimary, SectionTitleSecondary} from '../../component/Section'
import BookGuide                                                                from '../../component/BookGuide'
import OrderForm                                                           from '../../component/OrderFormModal'
import {getListProduct}                                                         from '../../../service/ProductService'
import './style.scss'
import SectionBackground                                                        from '../../../assets/images/section-background-1.png'
import { Button} from 'antd';
import { FlyIcon } from '../../component/Icon';
import {Link} from "react-router-dom";
import {createPromotionEmail}                       from "../../../service/PromotionService";
import {getInfoWebsite} from "../../../service/InfoService";
import MetaTags from "react-meta-tags";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [infoHome, setinfoHome] = useState([])

  useEffect(() => {
    async function getProductData() {
      let response   = await getListProduct(1)
      if(response && response.code === 0) {
        let tmpCategories = response.data.sub_category;
        let tmpResult = [];
        tmpResult.push({
          'name': 'Xe 4 - 10 chỗ',
          'products': mergeProduct(tmpCategories,[3, 4, 6])
        })

        tmpResult.push({
          'name': 'Xe 16 - 29 chỗ',
          'products': mergeProduct(tmpCategories,[5, 7])
        })

        tmpResult.push({
          'name': 'Xe 35 - 45 chỗ',
          'products': mergeProduct(tmpCategories,[8, 9])
        })
        setCategories(tmpResult);
      } else {
        // show error message
      }
    }

    getProductData();
    getDataWebsite();
  }, [])

  const getDataWebsite = async () => {
    let response   = await getInfoWebsite()
    if(response.code === 0) {
      let dataInfo = response.data
      setinfoHome(dataInfo)
    }
  }

  const mergeProduct = (listProduct, listId) => {
    let products = [];
    for (var i = 0; i < listProduct.length; i++) {
      var p = listProduct[i];
      if (listId.includes(p.id)) {
        products = products.concat(p.products);
      }
    }
    return products;
  }

  return (<div className="home-page">
    <MetaTags>
      <title>JAC - Công ty vận tải du lịch</title>
      <meta id="meta-keywords" name="keywords" content="JAC, thuê xe, thuê xe du lịch, thuê xe cưới hỏi, dịch vụ cứu hộ" />
      <meta id="meta-description" name="description" content="JAC - kinh doanh vận tải hành khách, kinh doanh dịch vụ du lịch, bảo dưỡng sửa chữa xe ô tô, kinh doanh phụ tùng ô tô, cứu hộ giao thông" />
    </MetaTags>
    <section className="home-page__banner">
      <div className="home-page__banner--order-form">
        <OrderForm/>
      </div>
      <div>
        <BannerSlider/>
      </div>
    </section>
    <section>
      <ServiceIntroduce/>
    </section>
    <section>
      {
        categories.map((category, key) => {
          return <div key={key}>
            <TitleLeftRight left="Các Dòng Xe" right={category.name}/>
            <ProductCarousel products={category.products}/>
          </div>
        })
      }

      <div className="text-center margin-top-lg">
        <ButtonTransparent><Link to="/danh-muc/loai-xe">Xem tất cả</Link></ButtonTransparent>
      </div>
    </section>
    <div className="margin-top-lg">
      <JacSection background={SectionBackground} align="right">
        <SectionTitleSecondary>Công ty TNHH MTV</SectionTitleSecondary>
        <SectionTitlePrimary>VẬN TẢI & DU LỊCH JAC</SectionTitlePrimary>
        <SectionContent>{infoHome.content_intro}</SectionContent>
        <ButtonTransparent><Link to="/ve-chung-toi">Xem thêm</Link></ButtonTransparent>
      </JacSection>
    </div>
    <section>
      <BookGuide/>
    </section>
    <div className="clearfix"></div>
  </div>)
}

export default Home