import React from 'react';
import './style.scss'
import {BusIcon} from '../Icon'


const TitleLeftRight = (props) => {
  return <div className="container">
    <div className="title-left-right">
      <div className="top">
        <div className="top__left">
          <div className="top__left--icon">
            <BusIcon size="100%"/>
          </div>
          <div className="top__left--content">
            {props.left}
          </div>
        </div>
        <div className="top__right">
          {props.right}
        </div>
      </div>
      <div className="border-bottom">
      </div>
    </div>
  </div>
}

export default TitleLeftRight