import Home from '../module/page/Home'
import Category from '../module/page/Category'
import AboutUs from '../module/page/AboutUs'
import Blog from '../module/page/Blog'
import BlogDetail from '../module/page/Blog/Detail'
import Product from '../module/page/Product'
import ServicePage from "../module/page/ServicePage";
export default [
  {
    path: '/ve-chung-toi',
    page: AboutUs
  },
  {
    path: '/danh-muc/:parentCategoryId?/:childCategoryId?',
    page: Category
  },
  {
    path: '/tin-tuc',
    page: Blog
  },
  {
    path: '/tin-tuc/:postId-:slugPost.html',
    page: BlogDetail
  },
  {
    path: '/san-pham/:productId-:slugPost.html',
    page: Product
  },
  {
    path: '/dich-vu-cuu-ho',
    page: ServicePage
  },
  {
    path: '/trang-chu',
    page: Home
  },
  {
    path: '/',
    page: Home
  }
]