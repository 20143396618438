import axios from 'axios'
import {API_URL, TYPE_CONTACT, TYPE_BANNER, TYPE_ABOUT_US, TYPE_SERVICE_SOS, TYPE_LOGO_CUSTOMER } from '../config'

const API     = {
  GET_INFO: API_URL + 'info/list'
}

var getInfoByType = async (type) => {
  try {
    let response = await axios.get(API.GET_INFO, {
      params: {
        type: type
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

var getListBanner = async () => {
  return getInfoByType(TYPE_BANNER);
}

var getInfoWebsite = async () => {
  return getInfoByType(TYPE_CONTACT);
}

var getAboutUsContent = async () => {
  return getInfoByType(TYPE_ABOUT_US);
}

var getServiceSOSContent = async () => {
  return getInfoByType(TYPE_SERVICE_SOS);
}

var getLogoCustomer = async () => {
  return getInfoByType(TYPE_LOGO_CUSTOMER);
}


export {getListBanner, getInfoWebsite, getAboutUsContent, getServiceSOSContent, getLogoCustomer}