import React from 'react';
import './style.scss'
import {ServiceIcon1, ServiceIcon2, ServiceIcon3, ServiceIcon4} from '../Icon'

const ServiceIntroduce = (props) => {
  return <div className="service-introduce">
    <h2 className="service-introduce__title-1">TẠI SAO</h2>
    <h3 className="service-introduce__title-2">CHỌN CHÚNG TÔI</h3>
    <ServiceIconsBox/>
  </div>
}

export const ServiceIconsBox = (props) => {
  return <div className={"service-icons-box " + (props.greyBg ? 'service-icons-box__grey' : '')}>
    <ServiceIntroduceItem icon={<ServiceIcon1/>} description="Đặt xe nhanh chóng" greyBg={props.greyBg}/>
    <ServiceIntroduceItem icon={<ServiceIcon2/>} description="Dịch vụ chuyên nghiệp" greyBg={props.greyBg}/>
    <ServiceIntroduceItem icon={<ServiceIcon3/>} description="Giá cả hợp lý" greyBg={props.greyBg}/>
    <ServiceIntroduceItem icon={<ServiceIcon4/>} description="Hỗ trợ nhiệt tình" greyBg={props.greyBg}/>
  </div>
}

export const ServiceIntroduceItem = (props) => {
  return <div className={"service-icon-box " + (props.greyBg ? 'service-icon-box__grey' : '')}>
    <div className="service-icon">
      {props.icon}
    </div>
    <div className="service-description">
      {props.description}
    </div>
  </div>
}



export default ServiceIntroduce