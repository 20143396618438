import React, {useEffect, useState}                                             from 'react';
import BlogMenu     from "../../../component/BlogMenu";
import {Col, Row} from "antd";

import './style.scss'
import RecentPost from "../../../component/RecentPost";
import PostDetail from "../../../component/PostDetail";
import {useParams} from "react-router-dom";
import {getBlogDetail} from "../../../../service/BlogService";
import {getInfoWebsite} from "../../../../service/InfoService";
import MetaTags from "react-meta-tags";

const BlogDetail = () => {
  const {postId}                     = useParams()
  const [post, setPost]           = useState({});
  const [infoNav, setinfoNav] = useState([])

  useEffect(() => {
    async function getBlogData() {
      let response = await getBlogDetail(postId)
      if (response.code === 0) {
        let postData = response.data
        setPost(postData);
      } else {
        // show error message
      }
    }

    getBlogData();
    getDataWebsite();
  }, [postId])

  const getDataWebsite = async () => {
    let response   = await getInfoWebsite()
    if(response.code === 0) {
      let dataInfo = response.data
      setinfoNav(dataInfo)
    }
  }
  
  return (<div className="blog-page">
    <MetaTags>
      <title>{post.meta_title}</title>
      <meta id="meta-keywords" name="keywords" content={post.meta_keyword} />
      <meta id="meta-description" name="description" content={post.meta_description} />
    </MetaTags>
    <div className="shadow"></div>
    <div className="container">
      <Row className="blog-main">
        <Col lg={18} sm={24} className="blog__posts">
          <PostDetail post={post} />
        </Col>
        <Col lg={6} sm={24} className="blog__navbar">
          <div className="blog__menu">
            <div className="blog__menu">
              <div className="navbar-menu">
                <div className="title-navbar title-blog">
                  <h2>VIDEO</h2>
                </div>
                <div dangerouslySetInnerHTML={{__html: infoNav.youtube_video}}></div>
              </div>
            </div>
          </div>
          <div className="blog__recent-post">
            <RecentPost titleNavbar="BÀI VIẾT GẦN ĐÂY"/>
          </div>
        </Col>
      </Row>
    </div>
  </div>)
}

export default BlogDetail