import axios from 'axios'
import {API_URL} from '../config'

const API     = {
  GET_PRODUCT_CATEGORY: API_URL + 'category'
}

var getProductCategory = async () => {
  try {
    let response = await axios.get(API.GET_PRODUCT_CATEGORY);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export {getProductCategory}