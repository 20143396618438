import React from 'react';
import './style.scss'

export default (props) => {
  return <div className={"section " + props.className}>
    <img className="section__background" src={props.background} alt="Vận tải du lịch JAC"/>
    <div className="section__content-box">
      <div className="container">
        <div className={"section__content " + (props.align === 'right' ? 'section__content--right-align' : 'section__content--center-align')}>
          {props.children}
        </div>
      </div>

    </div>
  </div>
}

export const SectionTitlePrimary = (props) => {
  return <h1 className={"section__title--primary " + props.className}>{props.children}</h1>
}

export const SectionTitleSecondary = (props) => {
  return <h2 className={"section__title--secondary "+ props.className}>{props.children}</h2>
}

export const SectionContent = (props) => {
  return <p className={"section__title--content "+ props.className}>{props.children}</p>
}