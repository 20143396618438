import React            from 'react';
import ProductThumbnail from '../ProductThumbnail'
import './style.scss'
import "react-multi-carousel/lib/styles.css";
import {ButtonTransparent} from "../Button";
import TitleLine from "../TitleLine";
import {
  Link
} from "react-router-dom";

const ProductGrid = (props) => {

  return <div className="product-grid-wrap">
      <TitleLine title={props.titleCategorySub} icon="1"/>
      <div className="clearfix"></div>
      <div className="product-grid">
        {
          props.products.map((product) => {
            return <div className="product-grid__item"><ProductThumbnail product={product}/></div>
          })
        }
      </div>
      <div className="text-right margin-top-md">
        {
          props.linkDetail &&
          <ButtonTransparent><Link to={props.linkDetail}>Xem tất cả</Link></ButtonTransparent>
        }

      </div>
  </div>;
}


export default ProductGrid