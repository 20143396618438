import React, {useEffect, useState}                                             from 'react';
import {Col, Row}                                                               from "antd";
import SectionBackground
                                                                                from '../../../assets/images/section-category-header.png';
import JacSection, {SectionContent, SectionTitlePrimary, SectionTitleSecondary} from '../../component/Section'
import './style.scss'
import FiveStar
                                                                                from "../../../assets/images/5-star.png";
import TitleLine                                                                from "../../component/TitleLine";
import ProductCarousel                                                          from "../../component/ProductCarousel";
import {getProductDetail}                                                       from "../../../service/ProductService";
import {Link, useParams}                                                        from "react-router-dom";
import Carousel                                                                 from "react-multi-carousel";
import ProductThumbnail                                                         from "../../component/ProductThumbnail";
import "react-multi-carousel/lib/styles.css";
import MetaTags from "react-meta-tags";

const Product = () => {
  const {productId}                     = useParams()
  const [product, setProduct]           = useState({});
  const [imageShowing, setImageShowing] = useState({});
  const [category, setCategory]         = useState({});
  const [images, setImages]             = useState([]);
  const [banner, setBanner]       = useState('');


  useEffect(() => {
    getProductData();
  }, [productId])

  const getProductData = async () => {
    console.log('productID', productId)
    let response = await getProductDetail(productId)
    if (response.code === 0) {
      let imageLinks  = []
      let productData = response.data
      setProduct(productData);

      let categories         = productData.categories
      let currentMaxPriority = 0;
      setCategory(categories[0]);
      for (var i = 0; i < categories.length; i++) {
        if (categories[i].priority > currentMaxPriority) {
          setCategory(categories[i]);
          currentMaxPriority = categories[i].priority

          if (categories[i].banner) {
            setBanner(categories[i].banner)
          } else {
            setBanner(SectionBackground)
          }
        }
      }
      if (productData.thumbnail) {
        setImageShowing(productData.thumbnail)
        imageLinks.push(productData.thumbnail)
      } else {
        setImageShowing(productData.image[0])
      }
      imageLinks.push(...productData.image)
      setImages(imageLinks)
    } else {
      // show error message
    }
  }

  return (<div className="product-page">
    <MetaTags>
      <title>{product.meta_title}</title>
      <meta id="meta-keywords" name="keywords" content={product.meta_keyword} />
      <meta id="meta-description" name="description" content={product.meta_description} />
    </MetaTags>
    <JacSection background={banner} align="center" className="category-header">
    </JacSection>
    <div className="container">
      <div className="product-main">
        <Row className="info-product">
          <Col lg={12} sm={12} className="product__gallery">
            <Row className="gallery-active-image">
              <img src={imageShowing} alt={product.name}/>
            </Row>
            <Row>
              <div className="gallery-image-wrap">
                <ProductImageCarousel images={images} setImageShowing={setImageShowing} productName={product.name}/>
              </div>
            </Row>
          </Col>
          <Col lg={12} sm={12} className="product__info">
            <div className="product__info--name">
              <h3>{product.name}</h3>
            </div>
            <div className="product__info--rate"><img src={FiveStar} alt="đánh giá sản phẩm"/></div>
            <div className="product__info--model">Đời xe: {product.model}</div>
            <div className="product__info--summary">
              <p>
                <div dangerouslySetInnerHTML={{__html: product.summary}}></div>
              </p>
            </div>
            <div className="product__info--contact">
              <Link to="/ve-chung-toi">Liên Hệ</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="description">
            <TitleLine title="CHI TIẾT"/>
            <div className="content">
              <div dangerouslySetInnerHTML={{__html: product.description}}></div>
            </div>
          </div>
        </Row>
        <Row>
          <div className="description information">
            <TitleLine title="THÔNG SỐ KỸ THUẬT"/>
            <div className="content">
              {product.information}
            </div>
          </div>
        </Row>
        <Row>
          <div className="relate-product">
            <TitleLine title="LOẠI XE TƯƠNG TỰ"/>
            <div className="products">
              {
                product.relate &&
                <ProductCarousel products={Object.values(product.relate)}/>
              }
            </div>
          </div>
        </Row>
      </div>
    </div>
  </div>)
}

export default Product


const ProductImageCarousel = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items     : 4
    },
    desktop          : {
      breakpoint: {max: 3000, min: 1024},
      items     : 4
    },
    tablet           : {
      breakpoint: {max: 1024, min: 464},
      items     : 3
    },
    mobile           : {
      breakpoint: {max: 464, min: 0},
      items     : 2
    }
  };

  return <Carousel
    responsive={responsive}
    itemClass="gallery-image__carousel-item-box"
    additionalTransfrom={0}
    customLeftArrow={<i className='custom-left-arrow'/>}
    customRightArrow={<i className='custom-right-arrow'/>}
    arrows
    autoPlaySpeed={3000}
    centerMode={false}
    className=""
    containerClass="container-with-dots"
    dotListClass=""
    draggable
    focusOnSelect={false}
    infinite
    keyBoardControl
    minimumTouchDrag={80}
    renderButtonGroupOutside={false}
    renderDotsOutside={false}
    showDots={false}
    sliderClass=""
    slidesToSlide={1}
    swipeable
  >
    {
      props.images ? props.images.map((image, key) => {
        return <div className="gallery-image-box" key={key} onClick={() => {
          props.setImageShowing(image)
        }}>
          <img className="gallery-image" src={image} alt={props.productName}/>
        </div>
      }) : ''
    }
  </Carousel>
}