import axios from 'axios'
import {API_URL, NEWS_POST_CATEGORY} from '../config'

const API     = {
  GET_LIST_BLOG: API_URL + 'post/list',
  GET_BLOG_DETAIL: API_URL + 'post/detail',
  GET_BLOG_NEWEST: API_URL + 'post/news'
}

var getListBlog = async (category) => {
  try {
    let response = await axios.get(API.GET_LIST_BLOG, {
      params: {
        category_id: category
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

var getBlogDetail = async (postId) => {
  try {
    let response = await axios.get(API.GET_BLOG_DETAIL, {
      params: {
        post_id: postId
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

var getBlogNewest = async (limit) => {
  try {
    let response = await axios.get(API.GET_BLOG_NEWEST, {
      params: {
        limit: limit
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

var getBlogMenu = async () => {
  try {
    var category = NEWS_POST_CATEGORY
    let response = await axios.get(API.GET_LIST_BLOG, {
      params: {
        category_id: category
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export {getListBlog, getBlogDetail, getBlogNewest, getBlogMenu}