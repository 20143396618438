import axios from 'axios'
import {API_URL} from '../config'

const API     = {
  POST_ORDER_CREATE: API_URL + 'order/create'
}

var createOrder = async (data) => {
  try {
    let response = await axios.post(API.POST_ORDER_CREATE, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export {createOrder}