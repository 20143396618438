import React, {useState, useEffect}                                             from 'react';
import JacSection, {SectionTitleSecondary, SectionTitlePrimary, SectionContent} from '../../component/Section'
import {Col, Row}                                                               from "antd";
import ServicePageImg                                                               from '../../../assets/images/image-service-page-1.png'
import ServicePageImg2                                                               from '../../../assets/images/image-service-page-2.png'
import SectionBackground                                                       from '../../../assets/images/background-service-page.png'
import {getServiceSOSContent} from "../../../service/InfoService";
import './style.scss'
import MetaTags from "react-meta-tags";

const ServicePage = () => {

  const [contentService, setContentService] = useState([])
  useEffect(() => {
      getDataService()
  }, []);

  const getDataService = async () => {
      let response   = await getServiceSOSContent()
      if(response.code === 0) {
          let dataInfo = response.data
          setContentService(dataInfo)
      }
  }

  return (<div className="service-page">
    <MetaTags>
      <title>JAC - Công ty vận tải du lịch - Dịch vụ cứu hộ</title>
      <meta id="meta-keywords" name="keywords" content="Dịch vụ cứu hộ, JAC, thuê xe, thuê xe du lịch, thuê xe cưới hỏi" />
      <meta id="meta-description" name="description" content="Dịch vụ cứu hộ, JAC - kinh doanh vận tải hành khách, kinh doanh dịch vụ du lịch, bảo dưỡng sửa chữa xe ô tô, kinh doanh phụ tùng ô tô, cứu hộ giao thông" />
    </MetaTags>
    <JacSection background={SectionBackground} align="center" className="service-page-header">
    </JacSection>
    <div className="container">
      <Row className="service-page-description-box">
        <Col lg={12} sm={24} className="service-page-description__image-wrap">
          <div className="service-page-description__image-box">
            <img className="service-page-description__image" src={ServicePageImg} alt="dịch vụ jac"/>
          </div>
        </Col>
        <Col lg={12} sm={24} className="service-page-description">
          <p className="service-page-description__content">
            <div dangerouslySetInnerHTML={{__html: contentService.block_text_2}}></div>
          </p>
        </Col>
      </Row>
      <div className="service-page-content">
        <div dangerouslySetInnerHTML={{__html: contentService.block_text_3}}></div>
      </div>
      <div className="service-page-image">
        <img src={ServicePageImg2} alt="dịch vụ jac"/>
      </div>
      <div className="service-page-content">
        <div dangerouslySetInnerHTML={{__html: contentService.block_text_4}}></div>
      </div>
    </div>
  </div>)
}

export default ServicePage