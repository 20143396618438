import React, {useEffect, useState} from 'react';
import {Carousel}                   from 'antd';
import './style.scss'
import {getListBanner}              from "../../../service/InfoService";
import {Link}          from "react-router-dom";

const BannerSlider = (props) => {
  const [banners, setBanners] = useState([])
  const [config, setConfig] = useState([])

  useEffect(() => {
    getDataBanner()
  }, [])

  const getDataBanner = async () => {
    let response = await getListBanner()
    if (response.code === 0) {
      let bannerData = response.data
      let bannerImages = bannerData.banners
      if (bannerImages.length === 1) {
        bannerImages.push(bannerImages[0])
      }
      setBanners(bannerImages)
      setConfig(bannerData.config)
    }
  }
  return <Carousel autoplay autoplaySpeed={config.delay_time} speed={config.speed_time}>
    {
      banners ? banners.map((banner, key) => {
        return (
          <a className="slider-item" key={key} href={banner.link} target="_blank">
            <img src={banner.image} alt={banner.alt}/>
          </a>
        );
      }) : ''
    }
  </Carousel>
}

export default BannerSlider