import React, {useState, useEffect}            from 'react';
import './style.scss'
import {format_blog_link, check_thumbnail_post} from '../../../utils/helper'
import {Link} from "react-router-dom";

const PostItemMini = (props) => {

  return <div className="post-item-mini">
    <Link to={format_blog_link(props.post)} className="image-mini">
      <img src={check_thumbnail_post(props.post.thumbnail)} alt={props.post.title} />
    </Link>
    <Link to={format_blog_link(props.post)} className="post-title">{props.post.title}</Link>
  </div>;
}

export default PostItemMini