import React, {useState, useEffect}            from 'react';
import './style.scss'
import PostItem from "../PostItem";
import ProductThumbnail from "../ProductThumbnail";

const ListPost = (props) => {

  return <div className="list-post">
    {
      props.posts ? props.posts.map((post) => {

        return <PostItem post={post}/>
      }) : ''
    }
  </div>;
}

export default ListPost