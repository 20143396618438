import React                                         from 'react';
import './style.scss';
import {Row, Col}                                    from 'antd';
import {MailOutlined, GlobalOutlined, PhoneOutlined} from '../../component/Icon'
import logo                                          from '../../../assets/images/logo.png'
import {Link, useLocation}                           from "react-router-dom";
import {format_phone_number} from '../../../utils/helper'

const Header = (props) => {
  return (
    <header>
      <div className="header-top">
        <Row className="container">
          <Col lg={12} sm={10} xs={12}>
            <span className="header-welcome">{props.info.welcome}</span>
          </Col>
          <Col lg={4} sm={4} xs={4}>
            <a href="mailto:jac@gmail.com">
              <span className="header-top--icon"><MailOutlined/></span>
              <span className="header-top--content">{props.info.email}</span>
            </a>
          </Col>
          <Col lg={3} sm={4} xs={3} className="text-center">
            <a href="/">
              <span className="header-top--icon"><GlobalOutlined/></span>
              <span className="header-top--content">{props.info.website}</span>
            </a>
          </Col>
          <Col lg={5} sm={6} xs={5} className="header__phone">
            <a href={"tel:" + props.info.phone}>
              <span className="header__phone--icon"><PhoneOutlined/></span>
              <span className="header__phone--number">{format_phone_number(props.info.phone)}</span>
            </a>
          </Col>
        </Row>
      </div>
      <div className="nav container">
        <Row className="container">
          <Col lg={8} sm={4} xs={4} className="logo-box"><Link to="/"><img src={logo} alt="jac logo"/></Link></Col>
          <Col lg={16} sm={20} xs={20}>
            <div className="nav__items">
              <NavItem title="Thuê xe dịch vụ" pathname="/danh-muc/thue-xe-dich-vu"/>
              <NavItem title="Thuê xe dài hạn" pathname="/danh-muc/thue-xe-dai-han"/>
              <NavItem title="Dịch vụ cứu hộ" pathname="/dich-vu-cuu-ho"/>
              <NavItem title="Về chúng tôi" pathname="/ve-chung-toi"/>
              <NavItem title="Tin tức - Khuyến mại" pathname="/tin-tuc"/>
            </div>
          </Col>
        </Row>
      </div>
    </header>)
}

const NavItem = (props) => {
  let location = useLocation();
  return <Link to={props.pathname} className="nav__item-box"><span
      className={"nav__item " + (location.pathname === props.pathname ? "nav__item--choosing" : '')}>{props.title}</span></Link>
}

export default Header