import React, {useState, useEffect}            from 'react';
import {CalenderIcon} from '../../component/Icon'
import './style.scss'
import {check_thumbnail_post, format_date} from '../../../utils/helper'

const PostDetail = (props) => {
  return <div className="post-detail">
    <h2 className="post-title">{props.post.title}</h2>
    <div className="time-post">
      <CalenderIcon />
      <span className="time-text">
        {format_date(props.post.created_at)}
      </span>
    </div>
    <div className="detail-text">
      <img src={check_thumbnail_post(props.post.thumbnail)} alt={props.post.title}/>
      <div dangerouslySetInnerHTML={{__html: props.post.content}} />
    </div>
  </div>;
}

export default PostDetail