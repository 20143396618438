import React      from 'react';
import './style.scss'
import guideStep1 from '../../../assets/images/guide-step-1.png'
import guideStep2 from '../../../assets/images/guide-step-2.png'
import guideStep3 from '../../../assets/images/guide-step-3.png'
import guideStep4 from '../../../assets/images/guide-step-4.png'

const BookGuide = (props) => {
  return <div className="book-guide__box">
    <h2 className="book-guide__title"><b>HƯỚNG DẪN</b> ĐẶT XE</h2>
    <div className="book-guide">
      <div className="book-guide__content">
        <GuideStep image={guideStep1} title="Đặt xe <br> Với JAC.vn" step={1}/>
        <GuideStep image={guideStep2} title="Tư vấn, <br> chốt lịch trình" step={2}/>
        <GuideStep image={guideStep3} title="Trải nghiệm <br> chuyến đi" step={3}/>
        <GuideStep image={guideStep4} title="Kết thúc <br> giao dịch" step={4}/>
      </div>
    </div>
  </div>
}

const GuideStep = (props) => {
  return <div className="guide-step-box">
    <div className="guide-step">
      <img className="guide-step__image" src={props.image} alt="hướng dẫn đặt xe"/>
      <div className="guide-step__content-box">
        <h3 className="guide-step__title" dangerouslySetInnerHTML={{__html: props.title}}>
        </h3>
        <div className="guide-step__step-box">
          <div className={"guide-step__step " + (props.step === 1 ? 'guide-step__step--active' : '')}/>
          <div className={"guide-step__step " + (props.step === 2 ? 'guide-step__step--active' : '')}/>
          <div className={"guide-step__step " + (props.step === 3 ? 'guide-step__step--active' : '')}/>
          <div className={"guide-step__step " + (props.step === 4 ? 'guide-step__step--active' : '')}/>
        </div>
      </div>
    </div>
  </div>
}

export default BookGuide