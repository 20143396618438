import React                                            from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import {compose, withProps}                             from "recompose"

const MapComponent = compose(
  withProps({
    googleMapURL    : "https://maps.googleapis.com/maps/api/js?key=AIzaSyDlkPRpU8Qk221zsdBOpn8cVl_WDSBtIWk&v=3.exp&libraries=geometry,drawing,places",
    loadingElement  : <div style={{height: `100%`}}/>,
    containerElement: <div style={{height: `100%`, width: '100%'}}/>,
    mapElement      : <div style={{height: `100%`}}/>,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap

    defaultZoom={16}
    defaultCenter={{lat: 21.0818986, lng: 105.8188412}}
  >
      {props.isMarkerShown && <Marker position={{lat: 21.0818986, lng: 105.8188412}} label="Công ty TNHH MTV Vận Tải và Du Lịch JAC" onClick={props.onMarkerClick}></Marker>}
  </GoogleMap>
)

export default MapComponent