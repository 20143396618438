import React           from 'react';
import BaseIcon        from "./base";
import facebookIconSrc from "../../../assets/images/facebook-icon.png";
import instaIconSrc    from "../../../assets/images/insta-icon.png";
import mesIconSrc      from "../../../assets/images/mes-icon.png";
import youtubeIconSrc  from "../../../assets/images/youtube-icon.png";
import serviceIconSrc1  from "../../../assets/images/service-icon-1.png";
import serviceIconSrc2  from "../../../assets/images/service-icon-2.png";
import serviceIconSrc3  from "../../../assets/images/service-icon-3.png";
import serviceIconSrc4  from "../../../assets/images/service-icon-4.png";
import busIcon  from "../../../assets/images/bus-icon.png";
import LeftArrowImg  from "../../../assets/images/left-arrow.png";
import RightArrowImg  from "../../../assets/images/right-arrow.png";
import MenuIconSrc  from "../../../assets/images/icon-menu.png";
import CalenderIconSrc  from "../../../assets/images/calender-icon.png";
import CarIconSrc  from "../../../assets/images/car-icon.png";
import MailIconSrc  from "../../../assets/images/icon-email.png";
import GlobalIconSrc  from "../../../assets/images/icon-website.png";
import PhoneIconSrc  from "../../../assets/images/icon-phone-small.png";
import FlyIconSrc  from "../../../assets/images/icon-fly.png";
import AddressFromIconSrc  from "../../../assets/images/icon-address-from.png";
import AddressToIconSrc  from "../../../assets/images/icon-address-to.png";
import AddressFooterIconSrc  from "../../../assets/images/icon-address-footer.png";
import PhoneFooterIconSrc  from "../../../assets/images/icon-phone-footer.png";
import EmailFooterIconSrc  from "../../../assets/images/icon-email-footer.png";
import CollapOrderIconSrc  from "../../../assets/images/icon-collap-order.png";
import ShowMapsIconSrc       from "../../../assets/images/show-map-icon.png";
import OrderCarIconSrc       from "../../../assets/images/icon-dat-xe.png";
import OrderCarIconBackgroundSrc       from "../../../assets/images/icon-dat-xe-background.png";

export const FacebookIcon = (props) => {
  return (
    <BaseIcon src={facebookIconSrc} className={props.className} size={props.size ? props.size : 30}/>
  )
}

export const YoutubeIcon = (props) => {
  return (
    <BaseIcon src={youtubeIconSrc} className={props.className} size={props.size ? props.size : 30}/>
  )
}

export const InstaIcon = (props) => {
  return (
    <BaseIcon src={instaIconSrc} className={props.className} size={props.size ? props.size : 30}/>
  )
}

export const MesIcon = (props) => {
  return (
    <BaseIcon src={mesIconSrc} className={props.className} size={props.size ? props.size : 30}/>
  )
}

export const ServiceIcon1 = (props) => {
  return (
    <BaseIcon src={serviceIconSrc1} className={props.className} size={props.size ? props.size : ''}/>
  )
}

export const ServiceIcon2 = (props) => {
  return (
    <BaseIcon src={serviceIconSrc2} className={props.className} size={props.size ? props.size : ''}/>
  )
}

export const ServiceIcon3 = (props) => {
  return (
    <BaseIcon src={serviceIconSrc3} className={props.className} size={props.size ? props.size : ''}/>
  )
}

export const ServiceIcon4 = (props) => {
  return (
    <BaseIcon src={serviceIconSrc4} className={props.className} size={props.size ? props.size : ''}/>
  )
}

export const BusIcon = (props) => {
  return (
    <BaseIcon src={busIcon} className={props.className} size={props.size ? props.size : 25}/>
  )
}


export const LeftArrow = (props) => {
  return (
    <BaseIcon src={LeftArrowImg} className={props.className} size={props.size ? props.size : 25}/>
  )
}

export const RightArrow = (props) => {
  return (
    <BaseIcon src={RightArrowImg} className={props.className} size={props.size ? props.size : 25}/>
  )
}

export const MenuIcon = (props) => {
  return (
    <BaseIcon src={MenuIconSrc} className={props.className}/>
  )
}

export const CalenderIcon = (props) => {
  return (
    <BaseIcon src={CalenderIconSrc} className={props.className}/>
  )
}

export const CarIcon = (props) => {
  return (
    <BaseIcon src={CarIconSrc} className={props.className}/>
  )
}

export const MailOutlined = (props) => {
  return (
    <BaseIcon src={MailIconSrc} className={props.className}/>
  )
}

export const GlobalOutlined = (props) => {
  return (
    <BaseIcon src={GlobalIconSrc} className={props.className}/>
  )
}

export const PhoneOutlined = (props) => {
  return (
    <BaseIcon src={PhoneIconSrc} className={props.className}/>
  )
}

export const FlyIcon = (props) => {
  return (
    <BaseIcon src={FlyIconSrc} className={props.className}/>
  )
}

export const AddressFromIcon = (props) => {
  return (
    <BaseIcon src={AddressFromIconSrc} className={props.className}/>
  )
}

export const AddressToIcon = (props) => {
  return (
    <BaseIcon src={AddressToIconSrc} className={props.className}/>
  )
}

export const ShowMapsIcon = (props) => {
  return (
    <BaseIcon src={ShowMapsIconSrc} className={props.className}/>
  )
}

export const AddressFooterIcon = (props) => {
  return (
      <BaseIcon src={AddressFooterIconSrc} className={props.className}/>
  )
}

export const PhoneFooterIcon = (props) => {
  return (
      <BaseIcon src={PhoneFooterIconSrc} className={props.className}/>
  )
}

export const EmailFooterIcon = (props) => {
  return (
      <BaseIcon src={EmailFooterIconSrc} className={props.className}/>
  )
}

export const CollapOrderIcon = (props) => {
  return (
      <BaseIcon src={CollapOrderIconSrc} className={props.className}/>
  )
}

export const OrderCarIcon = (props) => {
  return (
      <BaseIcon src={OrderCarIconSrc} className={props.className}/>
  )
}

export const OrderCarIconBackground = (props) => {
  return (
      <BaseIcon src={OrderCarIconBackgroundSrc} className={props.className}/>
  )
}