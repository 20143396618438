import React, {useEffect, useState} from 'react';
import './styles/index.scss';
import routers from "./config/router";
import {
  Switch,
  Route
}              from "react-router-dom";
import Header  from './module/layout/Header';
import Footer  from './module/layout/Footer';
import "antd/dist/antd.css";
import axios from './utils/axios'
import {getInfoWebsite} from "./service/InfoService";
import ScrollToTop from "./ScrollToTop";

function App() {

    const [infoHeader, setinfoHeader] = useState([])
    const [infoFooter, setinfoFooter] = useState([])

    useEffect(() => {
        getDataWebsite()
    }, [])

    const getDataWebsite = async () => {
        let response   = await getInfoWebsite()
        if(response.code === 0) {
            let dataInfo = response.data
            setinfoHeader(dataInfo)
            setinfoFooter(dataInfo)
        }
    }

  return (
    <div className="App">
      <Header info={infoHeader} />
      <ScrollToTop />
      <Switch>
        {routers.map((e, key) => {
          return <Route key={key} path={e.path} exact component={e.page} >
          </Route>
        })}
      </Switch>
      <Footer info={infoFooter} />
    </div>
  );
}

export default App;
