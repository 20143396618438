import React, {useState, useEffect}                                             from 'react';
import BlogMenu                            from "../../component/BlogMenu";
import {Col, Row}                          from "antd";

import './style.scss'
import SectionBackground                   from "../../../assets/images/section-blog-header.png";
import JacSection, {SectionTitleSecondary} from "../../component/Section";
import RecentPost                          from "../../component/RecentPost";
import ListPost                            from "../../component/ListPost";
import {useParams} from "react-router";
import {getListBlog} from "../../../service/BlogService";
import {getInfoWebsite} from "../../../service/InfoService";
import MetaTags from "react-meta-tags";

const Blog = () => {
  const [posts, setPosts]       = useState([]);
  const [infoNav, setinfoNav] = useState([])

  useEffect(() => {
    getPostsData();
    getDataWebsite();
  }, [])

  async function getPostsData() {
    var categoryId = '2'

    let response   = await getListBlog(categoryId)
    if(response.code === 0) {
      setPosts(response.data)
    } else {
      // show error message
    }
  }

  const getDataWebsite = async () => {
    let response   = await getInfoWebsite()
    if(response.code === 0) {
      let dataInfo = response.data
      setinfoNav(dataInfo)
    }
  }

  return (<div className="blog-page">
    <MetaTags>
      <title>JAC - Công ty vận tải du lịch - Tin tức khuyến mại</title>
      <meta id="meta-keywords" name="keywords" content="Tin tức khuyến mại, JAC, thuê xe, thuê xe du lịch, thuê xe cưới hỏi, dịch vụ cứu hộ" />
      <meta id="meta-description" name="description" content="Tin tức khuyến mại, JAC - kinh doanh vận tải hành khách, kinh doanh dịch vụ du lịch, bảo dưỡng sửa chữa xe ô tô, kinh doanh phụ tùng ô tô, cứu hộ giao thông" />
    </MetaTags>
    <JacSection background={SectionBackground} align="center" className="blog-header">
      <SectionTitleSecondary className="blog-title-secondary">TIN TỨC - KHUYẾN MẠI</SectionTitleSecondary>
    </JacSection>

    <div className="container">
      <Row className="blog-main">
        <Col lg={18} sm={24} className="blog__posts">
          <ListPost posts={posts}/>
        </Col>
        <Col lg={6} sm={24} className="blog__navbar">
          <div className="blog__menu">
            <div className="navbar-menu">
              <div className="title-navbar title-blog">
                <h2>VIDEO</h2>
              </div>
              <div dangerouslySetInnerHTML={{__html: infoNav.youtube_video}}></div>
            </div>
          </div>
          <div className="blog__recent-post">
            <RecentPost titleNavbar="BÀI VIẾT GẦN ĐÂY"/>
          </div>
        </Col>
      </Row>
    </div>
  </div>)
}

export default Blog