import React            from 'react';
import Carousel         from "react-multi-carousel";
import ProductThumbnail from '../ProductThumbnail'
import './style.scss'
import "react-multi-carousel/lib/styles.css";

const ProductCarousel = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items     : 4
    },
    desktop          : {
      breakpoint: {max: 3000, min: 1024},
      items     : 4
    },
    tablet           : {
      breakpoint: {max: 1024, min: 464},
      items     : 3
    },
    mobile           : {
      breakpoint: {max: 464, min: 0},
      items     : 2
    }
  };


  return <div className="product-carousel">
    <div className="container-1">
      <Carousel
        responsive={responsive}
        itemClass="carousel-item-box"
        additionalTransfrom={0}
        customLeftArrow={<i className='custom-left-arrow'/>}
        customRightArrow={<i className='custom-right-arrow'/>}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {
          props.products ? props.products.map((product) => {

            return <ProductThumbnail product={product} key={product.id}/>
          }) : ''
        }
      </Carousel>
    </div>
  </div>;
}


export default ProductCarousel