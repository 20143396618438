import React, {useState, useEffect}     from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
}                                       from "react-google-maps";
import {compose, withProps}             from "recompose"
import LocationSearchInput              from './searchLocation'
import {AddressFromIcon, AddressToIcon} from '../../component/Icon'
import './style.scss'

/*global google*/
const Maps = compose(
  withProps({
    googleMapURL    : "https://maps.googleapis.com/maps/api/js?key=AIzaSyDlkPRpU8Qk221zsdBOpn8cVl_WDSBtIWk&v=3.exp&libraries=geometry,drawing,places",
    loadingElement  : <div style={{height: `100%`}}/>,
    containerElement: <div style={{height: `100%`, width: '100%'}}/>,
    mapElement      : <div style={{height: `100%`}}/>,
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const [directionsService, setDirectionsService] = useState(null)
  const [toLocation, setToLocation]               = useState(null)
  const [fromLocation, setFromLocation]           = useState(null)
  const [directions, setDirections]               = useState(null)

  useEffect(() => {
    const DirectionsService = new google.maps.DirectionsService();
    setDirectionsService(DirectionsService)

  }, [])

  useEffect(() => {
    if (fromLocation && toLocation) {
      directionsService.route({
        origin: fromLocation,
        destination: toLocation,
        travelMode : google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
          let distance = Math.round(result.routes[0].legs[0].distance.value / 1000)
          props.setDistance(distance)
          props.setDuration(convertDuration(result.routes[0].legs[0].duration.value))
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  }, [fromLocation, toLocation])

  const convertDuration = (seconds) => {
    let hour = Math.floor(seconds / 3600)
    let minute = Math.round((seconds - hour * 3600) / 60)

    if(hour) {
      return hour + " giờ " + minute + ' phút'
    } else {
      return minute + ' phút'
    }
  }

  useEffect(() => {
    props.setInputForm(<LocationSearchInput
      addonBefore={<span><AddressFromIcon/> Điểm đón</span>}
      inHanoi={true}
      setLocation={setFromLocation}
      setMyAddress={props.setFromAddress}
      placeholder="Nhập điểm đón"
    />)
    props.setInputTo(<LocationSearchInput
      addonBefore={<span><AddressToIcon/> Điểm đên</span>}
      setLocation={setToLocation}
      setMyAddress={props.setToAddress}
      placeholder="Nhập điểm đến"
    />)
  }, [fromLocation, toLocation])

  return <div>
    {/*<GoogleMap*/}
    {/*  defaultZoom={16}*/}
    {/*  defaultCenter={{lat: 21.017373, lng: 105.7829305}}*/}
    {/*>*/}
    {/*  {directions &&*/}
    {/*  <DirectionsRenderer directions={directions} defaultDirections={null}/>    }*/}

    {/*</GoogleMap>*/}
  </div>
})


export default Maps