import React, {useState, useEffect}            from 'react';
import {CalenderIcon} from '../../component/Icon'
import './style.scss'
import {ButtonTransparent} from "../Button";
import {Link} from "react-router-dom";
import {format_date, format_blog_link, check_thumbnail_post} from '../../../utils/helper'

const PostItem = (props) => {

  return <div className="post-item">
    <h2>
      <Link to={format_blog_link(props.post)} className="post-title">{props.post.title}</Link>
    </h2>

    <div className="time-post">
      <CalenderIcon />
      <span className="time-text">{format_date(props.post.created_at)}</span>
    </div>
    <Link to={format_blog_link(props.post)} className="thumbnail-post-item">
      <img src={check_thumbnail_post(props.post.thumbnail)} alt={props.post.title} />
    </Link>
    <div className="summary-text">
      {props.post.title}
    </div>
    <ButtonTransparent><Link to={format_blog_link(props.post)}>Xem thêm</Link></ButtonTransparent>
  </div>;
}

export default PostItem