import React            from 'react';
import './style.scss'
import Carousel         from "react-multi-carousel";
// import ProductThumbnail from "../ProductThumbnail";


const FeedBack = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items     : 2
    },
    desktop          : {
      breakpoint: {max: 3000, min: 1024},
      items     : 2
    },
    tablet           : {
      breakpoint: {max: 1024, min: 464},
      items     : 2
    },
    mobile           : {
      breakpoint: {max: 464, min: 0},
      items     : 1
    }
  };

  return <div className="feed-back-wrap">
    <h2 className="feed-back__title">FEEDBACK KHÁCH HÀNG</h2>
    <div className="container-1">
      <Carousel
        responsive={responsive}
        itemClass="feed-back__container"
        additionalTransfrom={0}
        customLeftArrow={<i className='feed-back__left-arrow'/>}
        customRightArrow={<i className='feed-back__right-arrow'/>}
        arrows
        draggable={false}
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
      >
        {
          props.feedBacks.map((feedBack) => {
            return <Comment feedBack={feedBack}/>
          })
        }
      </Carousel>
    </div>
  </div>;
}

const Comment = (props) => {
  return <div className="comment-box noselect">
    <div className="comment__avatar">
      <img src={props.feedBack.avatar} alt="khách hàng jac" draggable={false}/>
    </div>
    <div className="comment__container">
      <div className="comment__container--content">{props.feedBack.content}</div>
      <div className="comment__container--address">{props.feedBack.address}</div>
    </div>
  </div>
}

export default FeedBack