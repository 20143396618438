import axios from 'axios'
import {API_URL} from '../config'

const API     = {
  GET_LIST_PRODUCT: API_URL + 'category/{slug}/product',
  GET_PRODUCT_DETAIL: API_URL + 'product/{productId}'
}

var getListProduct = async (categorySlug, limit) => {
  try {
    var params = {}
    if(limit) {
      params.limit = limit
    }

    let response = await axios.get(API.GET_LIST_PRODUCT.replace('{slug}', categorySlug), {
      params
    });

    return response.data;
  } catch (error) {
    return error.response;
  }
}

var getProductDetail = async (productId) => {
  try {
    let response = await axios.get(API.GET_PRODUCT_DETAIL.replace('{productId}', productId));

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export {getListProduct, getProductDetail}