import React from 'react';
import './style.scss'
import {CarIcon} from "../Icon";

const TitleLine = (props) => {
  return <div className="title-line">
    <div className="content-title-wrap">
      {props.icon === "1" &&
        <div className="icon-title">
          <CarIcon />
        </div>
      }
      <div className="content-title">
        <h3>{ props.title }</h3>
      </div>
    </div>
    <div className="line-title"></div>
  </div>;
}

export default TitleLine