import React, {useState, useEffect}            from 'react';
import './style.scss'
import PostItemMini from "../PostItemMini";
import {getBlogNewest, getListBlog} from "../../../service/BlogService";
import {LIMIT_NEWEST_POST} from "../../../config";

const RecentPost = (props) => {
  const [posts, setPosts]       = useState([]);

  useEffect(() => {
    let limit = LIMIT_NEWEST_POST
    getDataBlogNewest(limit);
  }, [])

  async function getDataBlogNewest(limit) {
    let response   = await getBlogNewest(limit)
    if(response.code === 0) {
      setPosts(response.data)
    } else {
      // show error message
    }
  }
  return <div className="navbar-recent-post">
    <div className="title-navbar">
      <h2>{props.titleNavbar}</h2>
    </div>
    <div className="navbar__list-post">
      {
        posts ? posts.map((post) => {

          return <PostItemMini post={post}/>
        }) : ''
      }
    </div>
  </div>;
}

export default RecentPost